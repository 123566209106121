import React from "react"

import SEO from "../../../components/seo"
import Header from "../../../components/header"
import InfoModal from "../../../components/info-modal"

import { ImagePanorama, Viewer, Infospot } from "panolens"
import { navigate } from "gatsby"
import LoadingBar from "../../../components/loading-bar"
import PanoContainer from "../../../components/pano-container"
import { Howl } from "howler"
import QuestionModal from "../../../components/question-modal"

import { Vector3 } from "three"

// Custom Icons
import QuestionMark from "../../../../static/icons/question-mark-white.png"
import ArrowMark from "../../../../static/icons/arrow-mark-white.png"
import EmergencyMark from "../../../../static/icons/emergency-mark-white.png"
import ToolBar from "../../../components/tool-bar"

export default class Reception extends React.Component {
  constructor() {
    super()
    this.state = {
      currentQuestion: "",
      infoModalIsOpen: false,
      questionModalIsOpen: false,
      progress: 0,
    }
  }

  setPositionToTarget(target, viewer) {
    const { x, y, z } = target.normalize()
    viewer.camera.position.set(x, -y, -z)
  }

  createQuestionInfoSpot(question, position) {
    const questionInfoSpot = new Infospot(300, QuestionMark, false)
    questionInfoSpot.position.set(position.x, position.y, position.z)
    questionInfoSpot.addHoverText(question)
    questionInfoSpot.addEventListener("click", () => {
      this.setState({
        currentQuestion: question,
        questionModalIsOpen: true,
      })
    })

    return questionInfoSpot
  }

  componentDidMount() {
    // Creating the different Panorama objects
    const mainPanorama = new ImagePanorama(
      "/departments/reception/panorama.png"
    )
    const receptionPanorama = new ImagePanorama(
      "/departments/reception/reception-panorama.png"
    )
    const laptopPanorama = new ImagePanorama(
      "/departments/reception/laptop-panorama.png"
    )

    // Progress Events
    mainPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    receptionPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    laptopPanorama.addEventListener("progress", progressEvent => {
      this.loadingProgress(progressEvent)
    })

    mainPanorama.setLinkingImage(ArrowMark, 350)
    receptionPanorama.setLinkingImage(ArrowMark, 350)
    laptopPanorama.setLinkingImage(ArrowMark, 350)

    // Linking Panorama's
    mainPanorama.link(receptionPanorama, new Vector3(4724.5, -557.21, 1525.19))
    receptionPanorama.link(
      mainPanorama,
      new Vector3(-3085.07, -3413.85, -1938.28)
    )

    receptionPanorama.link(
      laptopPanorama,
      new Vector3(4884.95, -918.15, 504.61)
    )
    laptopPanorama.link(receptionPanorama, new Vector3(4892.16, 1012.55, -8.72))

    // Create the InfoSpot to Go to the next department
    const doorInfoSpot = new Infospot(350, ArrowMark, false)
    doorInfoSpot.position.set(-4513.63, -247.12, 2118.49)
    doorInfoSpot.addHoverText("Go to the next department")
    doorInfoSpot.addEventListener("click", () => {
      navigate("/departments/baking-a-cake/")
    })

    const emergencyExitSpot = new Infospot(350, EmergencyMark, false)
    emergencyExitSpot.position.set(-688.19, 3156.26, 3807.67)
    emergencyExitSpot.addHoverText("Go to the emergency exit")
    emergencyExitSpot.addEventListener("click", () => {
      navigate("/departments/the-emergency-exit/")
    })

    // Question Marks
    const socialPressureQuestion = this.createQuestionInfoSpot(
      "I feel the social pressure of celebrating my birthday",
      new Vector3(2615.38, -3542.62, 2366.94)
    )

    const worriedPartyQuestion = this.createQuestionInfoSpot(
      "I am always worried that my own birthday party will suck",
      new Vector3(703.46, 944.69, -4849.04)
    )

    // Add the InfoSpots to the Main Panorama
    mainPanorama.add(doorInfoSpot)
    mainPanorama.add(emergencyExitSpot)
    mainPanorama.add(socialPressureQuestion)
    mainPanorama.add(worriedPartyQuestion)

    const invitationQuestion = this.createQuestionInfoSpot(
      "It is not OK to enter a birthday party without invitation",
      new Vector3(1896.61, 1069.95, 4493.26)
    )

    const celebrationQuestion = this.createQuestionInfoSpot(
      "A celebration without others is not a celebration",
      new Vector3(1316.09, 1512.02, -4573.28)
    )

    const guestOrHostQuestion = this.createQuestionInfoSpot(
      "I am rather a guest than a host",
      new Vector3(-2102.65, -511.14, -4499.55)
    )

    receptionPanorama.add(invitationQuestion)
    receptionPanorama.add(celebrationQuestion)
    receptionPanorama.add(guestOrHostQuestion)

    const birthdayQuestion = this.createQuestionInfoSpot(
      "My door is always open for guests on my birthday",
      new Vector3(3394.15, 1561.54, 3313.05)
    )

    const doorbellQuestion = this.createQuestionInfoSpot(
      "I get nervous when the doorbell rings",
      new Vector3(3002.18, -1171.48, 3814.79)
    )

    const hostQuestion = this.createQuestionInfoSpot(
      "It makes sense to be a host at your own birthday",
      new Vector3(838.28, -4927.05, -21.82)
    )

    laptopPanorama.add(birthdayQuestion)
    laptopPanorama.add(doorbellQuestion)
    laptopPanorama.add(hostQuestion)

    const viewer = new Viewer({
      cameraFov: 90,
      container: document.getElementById("pano-container"),
      controlBar: false,
      output: "console",
      autoHideInfospot: false,
    })

    // Set initial Camera position
    this.setPositionToTarget(new Vector3(4982.35, 66.95, -286.52), viewer)

    viewer.add(mainPanorama)
    viewer.add(receptionPanorama)
    viewer.add(laptopPanorama)

    // Setup the new Howl.
    this.sound = new Howl({
      src: ["/departments/reception/reception.mp3"],
    })

    // Play the sound.
    this.sound.play()

    // Fade In
    this.sound.fade(0.0, 0.5, 2000)
  }

  componentWillUnmount() {
    this.sound.fade(0.5, 0.0, 1000)
  }

  loadingProgress(progressEvent) {
    var loaded = progressEvent.progress.loaded
    var total = progressEvent.progress.total
    var completedProgress = 365
    var percentage = Math.round((loaded / total) * completedProgress)

    this.setState({
      progress: percentage,
    })
  }

  closeInfoModal = () => {
    this.setState({
      infoModalIsOpen: false,
    })
  }

  closeQuestionModal = () => {
    this.setState({
      questionModalIsOpen: false,
    })
  }

  render() {
    return (
      <>
        <SEO title="Reception" />
        <Header menuIsVisible={false} />
        <PanoContainer />
        <ToolBar
          onInfoClick={() =>
            this.setState({
              infoModalIsOpen: true,
            })
          }
          muteSound={() => this.sound.mute(!this.sound.mute())}
        />
        <LoadingBar progress={this.state.progress} />
        <InfoModal
          isOpen={this.state.infoModalIsOpen}
          onRequestClose={this.closeInfoModal}
          title="Reception"
        >
          <p>
            Welcome! Welcome! I am giving a party. Because it is my birthday.
            You did probably already receive my invitation? And if you come
            over, you’ll get even more. Something to drink, a chair to sit on,
            some party decoration to look at, a piece of cake, maybe some music,
            the presence of other guests, my generous hospitable smile...
          </p>
          <p>Let me know if you can make it!!</p>
        </InfoModal>
        <QuestionModal
          isOpen={this.state.questionModalIsOpen}
          onRequestClose={this.closeQuestionModal}
          question={this.state.currentQuestion}
        ></QuestionModal>
      </>
    )
  }
}
